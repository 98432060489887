.block {
    grid-column: 1 / -1;
    margin-bottom: 110px;

    @include tablet {
        grid-column: 2 / -2;
    }

    .section-title{
        margin-bottom: 6px;
    }
}