.homepage-header {
	position: relative;
	overflow-x: clip;
	grid-column: 1 / -1;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 32px 0 100px;

	@include mobile-only {
		margin-bottom: 60px;
	}

	@include tablet {
		padding: 80px 0 180px;
	}
}

.homepage-header__content {
	position: relative;
	padding: 0 32px;
	width: 100%;
	color: #fff;
	text-align: center;

	@include tablet {
		width: 600px;
	}

	.section-title {
		color: #fff;
	}

	.description {
		margin-top: 24px;
	}

	.button--primary {
		margin: 4px auto 0;
		width: fit-content;
		color: #fff;
	}
}

.homepage-header__background {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100vw;
}

.homepage-header__background--mobile {
	min-width: 480px;
	width: 100vw;
	max-width: none;
}

.homepage-header__background--desktop {
	min-width: 1100px;
}

@include mobile-only {
	.homepage-header__background--desktop {
		display: none;
	}
}

@include tablet {
	.homepage-header__background--mobile {
		display: none;
	}
}
