.navigation {
	position: relative;
	z-index: 9;
	// height: $navigation-height;
	width: 100%;

	.container {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 32px;
		padding-bottom: 16px;
		height: 100%;

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: $grid-gap;
			height: 1px;
			width: 100%;
			max-width: calc(100% - #{$grid-gap * 2});
			background: $light-grey;

			@include mobile-only {
				left: $mobile-grid-padding;
				max-width: calc(100% - #{$mobile-grid-padding * 2});
			}
		}

		@include until-tablet-landscape {
			.navigation__items {
				display: none;
			}
		}
	}

	.navigation__items {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.logo {
		margin-bottom: 32px;
		width: 100%;

		@include until-tablet-landscape {
			margin-bottom: 0;
		}

		img {
			width: 100%;
		}
	}

	.navigation-item {
		position: relative;
		margin-left: $grid-gap;

		&:hover {
			&.has-children:before {
				opacity: 1;
			}

			.regular {
				color: #000;
				text-decoration: none;
			}

			.navigation-sub-items {
				display: flex;
			}
		}

		&.has-children {
			padding-right: 22px;

			&:after {
				content: url('data:image/svg+xml, <svg width="13" height="7" xmlns="http://www.w3.org/2000/svg"><path d="M6.33 6.528L12.1.755a.326.326 0 00.003-.464l-.194-.195a.328.328 0 00-.465 0L6.101 5.442.755.096a.328.328 0 00-.464 0L.096.291a.328.328 0 000 .464l5.77 5.773a.328.328 0 00.465 0z" fill="%23C84540" fill-rule="nonzero"/></svg>');
				position: absolute;
				top: -1px;
				right: 0;
			}

			&:before {
				content: "";
				position: absolute;
				z-index: 1;
				top: -38px;
				left: -12px;
				height: 86px;
				width: 200px;
				border-left: 1px solid $light-grey;
				opacity: 0;
			}
		}

		&.has-cta {
			margin-left: auto;
		}

		a {
			font-weight: lighter;
		}

		p {
			cursor: default;
		}

		.regular {
			position: relative;
			z-index: 1;
			color: $text-color;
		}
	}

	.navigation-sub-items {
		display: none;
		position: absolute;
		z-index: 1;
		top: 50px;
		left: -12px;
		flex-direction: column;
		padding: 12px 12px 8px;
		min-width: 250px;
		background: #fff;
		border-left: 1px solid $light-grey;
	}

	.navigation-sub-item {
		display: flex;
		padding: 12px 0;
	}
}

.navigation--dark {
	.container {
		border-color: #eee;

		&:after {
			background: rgba(255, 255, 255, 0.1);
		}
	}

	.navigation-item,
	.navigation-item:hover {
		.regular,
		.button--primary {
			color: $white;
		}

		&.has-children {
			&:before {
				border-left: 1px solid rgba(255, 255, 255, 0.1);
			}
		}
	}

	.navigation-sub-items {
		background: rgba($secondary-color-light, 0.95);
		border-left: 1px solid rgba(255, 255, 255, 0.1);

		a {
			color: $white;
		}
	}
}
