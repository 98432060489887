.page--employees {
    grid-gap: $grid-gap;

    .heading,
    .grid-inside {
        grid-column: 2 / -2;
    }

    .employee {
        grid-column: 1 / -1;

        @include tablet {
            grid-column: span 6;
        }
    }
}
