.block-text-and-image {
	.block__content {
		grid-column: 1 / 8;

		@include mobile-only {
			grid-row: 2 / 3;
			grid-column: 1 / -1;
			padding: 0 $mobile-grid-padding;
		}
	}

	.block__image {
		grid-column: 8 / 13;

		@include mobile-only {
			grid-row: 1 / 2;
			grid-column: 1 / -1;
		}
	}

	img {
		@include tablet {
			border-radius: $border-radius;
		}
	}

	.section-title {
		position: relative;
		padding: 0 0 12px;
		margin: 0 0 24px;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			height: 2px;
			width: 47px;
			background: $primary-color;
		}
	}

	.button--primary {
		margin: 32px 0 0;
		width: fit-content;
	}
}
