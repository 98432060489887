.page--jurisdiction {
    .page__content {
        grid-column: 2 / -2;

        @include tablet {
            grid-column: 4 / -4;
        }
    }

    .page__related {
        grid-column: 2 / -2;
    }

    .jurisdiction__info {
        margin-bottom: 32px;
    }
}