[class^="button--"]{
    -webkit-appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 0 32px;
    background: none;
    border: none;
    text-align: center;
    transition: all 0.2s;
}

.button--primary {
    position: relative;
    border: 1px solid currentColor;
    color: $primary-color;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 currentColor;

    &:hover {
        text-decoration: none;
        box-shadow: inset 0 0 0 1px currentColor;
    }
}