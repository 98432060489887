.footer {
    display: flex;
    padding: 72px 0;
    background: $secondary-color-light;
    color: $white;

    @include mobile-only {
        flex-direction: column;
        padding: 80px $mobile-grid-padding;
    }

    .title {
        margin-bottom: 8px;
    }

    a {
        margin-bottom: 8px;
        color: rgba($white, 0.8);
    }

    p {
        opacity: 0.5;
    }

    .col {
        grid-column: span 4;
        display: flex;
        flex-direction: column;

        &:first-child {
            grid-column: 2 / span 4;
        }

        @include mobile-only {
            &:not(:first-child){
                margin-top: 80px;
            }
        }
    }
}

.post-footer {
    padding: 8px 0;
    background: $secondary-color-dark;
    color: $white;

    .container {
        display: flex;

        @include mobile-only {
            flex-direction: column;

            p:not(:first-child) {
                margin-top: 8px;
            }
        }

        @include tablet {
            justify-content: space-between;
        }
    }

    a {
        margin-left: 8px;
        font-size: 20px;
        color: $white;
    }
}