.page--jurisdictions {
    grid-gap: $grid-gap;

    .heading,
    .grid-inside {
        grid-column: 2 / -2;
    }

    .jurisdiction {
        grid-column: 1 / -1;

        @include tablet {
            grid-column: span 3;
        }
    }
}