/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;

$grid-width: 1200px;
$grid-gap: 30px;
$grid-column-count: 12;
$mobile-grid-padding: 16px;

$navigation-height: 100px;
$border-radius: 10px;