.block-content {

    @include mobile-only {
        padding: 0 $mobile-grid-padding;
    }

    .grid-inside {
        margin: 32px 0 0;

        .jurisdiction {
            grid-column: 1 / -1;

            @include tablet {
                grid-column: span 3;
            }
        }

        .employee {
            grid-column: span 12;

            @include tablet {
                grid-column: span 6;
            }
        }
    }
}