.employee {
    position: relative;
    overflow: hidden;

    display: flex;
    border: 1px solid $light-grey;
    border-radius: $border-radius;

    a{
        color: $text-color;
    }

    .employee__info {
        position: relative;
        display: flex;
        flex-direction: column;

        padding: 40px 20px;
        width: 100%;

        @include mobile-only {
            padding: 24px 16px;
        }
    }

    .employee__image {
        background: $light-grey;
        height: 100%;
        flex: 0 0 47%;

        @include mobile-only {
            flex: 0 0 36%;
        }
    }

    .employee__contact {
        display: none;
    }

    .title {
        color: $title-color;

        @include tablet {
            margin: 0 0 12px;
        }
    }

    .role {
        position: relative;
        padding: 0 0 12px;
        margin: 0 0 24px;

        &:after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 2px;
            width: 47px;
            background: $primary-color;
        }
    }

    .chevron {
        position: absolute;
        right: 22px;
        bottom: 22px;
        height: 16px;
        width: 16px;

        @include mobile-only {
            right: 16px;
            bottom: 16px;
        }
    }
}