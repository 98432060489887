// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $primary-color;
  text-decoration: none;

  &:not(.button--primary):hover {
    color: $text-color;
    text-decoration: underline;
  }
}

body{
  &.scroll-lock {
    position: relative;
    overflow: hidden;
  }
}

picture.image {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    content-visibility: auto;
  }
}

img{
  max-width: 100%;
}

.redactor {
  > *:not(:first-child){
    margin-top: 32px;
  }

  ul {
    list-style: none;

    li{
      padding-left: 1em;

      &:not(:first-child){
        margin-top: 16px;
      }

      &::before {
        content: "\2022"; 
        color: $primary-color; 
        font-weight: bold; 
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  blockquote {
    position: relative;
    padding-left: 1em;

    &:before{
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background: $light-grey;
    }

    em {
      &:before,
      &:after {
        content: '“';
        display: inline-block;
        color: $primary-color;
        font-weight: bold;
        margin: 0 4px 0 0;
      }

      &:after {
        content: '”';
        margin: 0 0 0 4px;
      }
    }
  }
}