.page--employee {
    .image {
        max-width: 100%;
        border-radius: $border-radius;
    }

    .page__sidebar {
        grid-column: 2 / -2;

        @include tablet {
            grid-column: 2 / 6;

            .employee__info {
                display: none;
            }
        }

        @include desktop {
            grid-column: 2 / 5;

            .employee__info {
                display: none;
            }
        }
    }

    .page__content {
        grid-column: 2 / -2;

        @include tablet {
            grid-column: 6 / -2;
        }

        @include desktop {
            grid-column: 5 / -2;
        }

        @include mobile-only {
            .employee__info {
                display: none;
            }
        }
    }

    .page__related {
        grid-column: 2 / -2;
    }

    .employee__info {
        margin-bottom: 32px;

        .role {
            color: $primary-color;
        }
    }

    .employee__contact {
        display: flex;
        flex-direction: column;

        a {
            position: relative;
            padding: 12px 0 0 32px;
            word-break: break-word;

            i {
                position: absolute;
                top: 16px;
                left: 0;
                width: 18px;
                text-align: center;
            }
        }
    }

    .employee__bio {
        margin-top: 32px;
    }
}