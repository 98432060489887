.jurisdiction {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: 40px 20px 32px;
	border: 1px solid $light-grey;
	border-radius: $border-radius;

	text-align: center;
	color: $text-color;

	@include mobile-only {
		grid-column: span 12;
	}

	.icon {
		display: flex;
		align-items: center;
		margin-bottom: auto;
		height: 50px;
	}

	.title {
		margin: 12px 0;
		color: $title-color;
	}

	.description {
		margin-bottom: 16px;
	}

	.chevron {
		margin: auto 0 0;
		height: 16px;
		width: 16px;
	}
}
