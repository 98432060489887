// BASE GRID LAYOUT STYLING
.container {
  margin: 0 auto;
  padding: 0 $grid-gap;
  max-width: #{$grid-width + $grid-gap * 2};
  width: 100%;
  
  @include mobile-only {
    padding: 0 $mobile-grid-padding;
  }
}

.grid-container{
  width: 100%;

  display: grid;
  grid-template-columns: 0 repeat(3,1fr) 0;
  grid-column-gap: $mobile-grid-padding;

  @include tablet {
    grid-template-columns: 10px repeat($grid-column-count,1fr) 10px;
    grid-column-gap: $grid-gap;
  }

  @include container-min {
    grid-template-columns: 1fr repeat($grid-column-count, #{($grid-width - (($grid-column-count - 1) * $grid-gap)) / $grid-column-count}) 1fr;
  }
}

.grid-inside {
  display: grid;
  grid-template-columns: repeat($grid-column-count,1fr);
  grid-gap: $mobile-grid-padding;

  width: 100%;
  max-width: 100%; 

  @include tablet {
    grid-gap: $grid-gap;
  }
}

.page {
  padding: 40px 0;

  @include tablet {
    padding: 60px 0;
  }
}
