.page--content {
    grid-gap: $grid-gap;

    .section-title,
    .intro,
    .description {
        grid-column: 2 / -2;

        @include tablet {
            grid-column: 4 / -4;
        }
    }

    .image {
        grid-column: 1 / -1;

        @include tablet {
            grid-column: 3 / -3;

            img {
                border-radius: $border-radius;
            }
        }
    }
}