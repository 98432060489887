.page {
    .page-type {
        opacity: 0.6;
    }

    .page__related {
        grid-column: 2 / -2;
        margin-top: 60px;

        .subsection-title {
            margin-bottom: 16px;
        }

        .jurisdiction {
            grid-column: 1 / -1;

            @include tablet {
                grid-column: span 3;
            }
        }

        .employee {
            grid-column: span 12;

            @include tablet {
                grid-column: span 6;
            }
        }
    }

    hr {
        grid-column: 2 / -2;
        margin-top: 60px;
        border: none;
        border-top: 1px solid $light-grey;
    }
}