.page--contact {
    .section-title {
        grid-column: 2 / -2;
        max-width: 100%;

        @include tablet {
            grid-column: 2 / 8;
        }
    }

    .image {
        grid-column: 2 / -2;
        max-width: 100%;

        @include tablet {
            grid-column: 8 / -2;
        }
    }

    .map {
        grid-column: 2 / -2;

        display: flex;
        flex-direction: column;

        @include tablet {
            grid-column: 8 / -2;
        }

        iframe{
            max-width: 100%;
        }
    }

    .contact-details {
        grid-column: 2 / -2;
        text-align: left;

        @include tablet {
        grid-column: 2 / 8;
        }
    }
}