




























.navigation-menu-click {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;

  &:not(.navigation-menu-click--open) {
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
  }
}
