.info-block {
    display: flex;
    flex-direction: column;

    margin-top: 32px;
    border: 1px solid $light-grey;
    padding: 16px;
    border-radius: $border-radius;

    a{
        color: $text-color;
    }

   .redactor strong {
       color: $primary-color;
   }
}