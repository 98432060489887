// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any,
// and all typgraphic styling (H1 - H6, p, strong, em, i, blockquote)
// -----------------------------------------------------------------------------
//
// When preferred or needed, @font-face declarations can also be moved to
// separate _fonts.scss
// -----------------------------------------------------------------------------


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300&display=swap');

body {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.4;
    color: $text-color;
}

.section-title {
    font-size: 2.333em;
    font-style: italic;
    font-weight: lighter;
    line-height: 1.25;
    color: $title-color;

    strong {
        color: $primary-color;
    }
}

.subsection-title {
    font-size: 1.5em;
    font-style: italic;
    font-weight: lighter;
    line-height: 1;

    strong {
        color: $primary-color;
    }
}

.title {
    font-size: 1.333em;
    font-weight: bold;
    line-height: 1.1;

    @include mobile-only {
        font-size: 1.2em;
    }
}
